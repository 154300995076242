@import "../__global/global.scss";


.index_page_container{

  .dragon_image{
    width: 170px;
    height: auto;
  }

  .input_with_label{
    margin-top: 20px;
  }
  .go_to_session.secondary{
    margin-top: 15px;
    a{
      font-size: 14px;
    }
  }
}
