@import "../__global/global.scss";

.monster_list{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  max-width: 310px;
  margin-top: -100px;
  position: relative;
  max-height: 420px;
  overflow-y: auto;
h1{
  margin: 20px auto;
}
  button{
    margin-top: 30px;
  }

  .monsters{
    max-height:80%;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .monster{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 30px;
      margin-bottom: 25px;


      button{
        font-size: 15px;
        margin: 0;
        margin-top: 5px;
      }
      a{
        color:$main;
        text-transform: capitalize;
        font-size: 20px;
        margin-bottom: 0;
        margin-left: 5px;
      }
    }
  }
button.add{
  margin-top: 30px;
  a{
    font-size: 16px;
    font-weight: 600;
  }
}
}
