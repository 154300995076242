@import "../__global/global.scss";


.session_overview.container{
  justify-content: flex-start;
  height: auto;
  margin-top: 0;
  max-height: 420px;
  overflow-y: auto;
  .text{
    font-size: 20px;
    margin: 15px auto;
  }
  .monsters{
    min-height:170px;
    max-height: 220px;
    overflow-y: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 10px;
    button{
      width: 100%;
    }
  }
  .buttons{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 5px;
    width: 100%;
    button{
    margin: 8px auto;
    display: block;
      font-size: 15px;
      padding: 10px;
  }
  }
.secondary.monster{
  margin: 10px 0;
  font-size: 14px;
}
  button.danger{
    margin-top: 10px;
  }
}
