$main_font: 'Homemade Apple', cursive;
$sec_font:'Inter', sans-serif;
$main:#352305;
$warning:#9A0000;


button{
  background: $main;
  color:white;
  font-family: $sec_font;
  text-align: center;
  padding: 15px 20px;
  -webkit-appearance: none;
  user-select: none;
  cursor: pointer;
  pointer-events: all;

  white-space: nowrap;
  box-shadow:5px 5px 15px rgba(255,255,255,0.3);
  text-transform: uppercase;
  font-size: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius:20px;
  border:1px solid dimgrey;
  min-height:40px;
  &.main{
    border-radius:35px;
    min-width: 220px;
    width: auto;

  }
  &.secondary{
    border-radius:20px;
    padding: 10px 12px;
  }
  &.danger{
    background:$warning;
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 600;

  }
  &.monster{
    border-radius:10px;
  }
}
a{
  text-decoration: none;
  color:white;
  text-transform: uppercase;
  &:visited{
    color:white;
  }
}

.container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.text{
  max-width: 70%;
  white-space: pre-wrap;
  font-size: 18px;
  text-align: center;
  margin: 15px auto;

  &.warning{
    color:$warning;
  }
}

.input_with_label{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  label{
    color:$main;
    text-transform: uppercase;
    font-size: 15px;
  }
  input{
    background: $main;
    border-radius:5px;
    color: white;
    padding: 10px;
    -webkit-appearance: none;
    font-family: $sec_font;
  }
}

a.back_button{
  color:$main;
  text-decoration: underline;
  margin-top: 15px;
}
.to_home{
  position: absolute;
  top: 8px;
  left: 10px;
  width: 30px;
  height: 30px;
  z-index: 10;

  img{
    width: 100%;
    height: 100%;
  }
}
