@import "../__global/global.scss";

.deal_damage.container{
  width: 100%;
  max-height: 440px;
  overflow-y: auto;
  h1{
    margin-bottom: 0;
  }
  .back_button{
    margin-top: -7px;
    margin-bottom: 5px;
  }
  .monster_img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 3px solid $main;
  }

  .healths{
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    max-width: 200px;

    .max, .current{
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #053a05;
      font-size: 20px;
    }

      .health_number {
        width: 50px;
        height: 50px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 2px solid forestgreen;
        margin-left: 10px;
        background: #dbffdb;
        font-weight: 600;
        text-transform: uppercase;
        font-feature-settings: "lnum";
      }
  }
  .deal_damage_input_button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    input{
      width: 50px;
    }
    .deal_damage.danger{
      margin-left: 15px;
      font-size: 14px;
      border-radius:5px;
      width: auto;
      height: 40px;
    }
  }

  button.danger{
    margin-top: 20px;
    width: 200px;
  }

}
