@import "../__global/global.scss";


.add_monster {
  h1 {
    margin-bottom: 10px;
  }

  button {
    margin-top: 20px;
  }
}
