@import "../__global/global.scss";


.monster.container {

  .monster_img {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    border: 3px solid $main;
  }

  .max_health {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #053a05;
    font-size: 20px;

    .health_number {
      width: 50px;
      height: 50px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4px;
      box-sizing: border-box;
      border-radius: 50%;
      border: 2px solid forestgreen;
      margin-left: 10px;
      background: #dbffdb;
      font-weight: 600;
      text-transform: uppercase;
      font-feature-settings: "lnum";
    }
  }
  .info{
    text-decoration: underline;
    margin: 15px 0;
    font-size: 15px;

    a{

    color:$main;
    }
  }

}

