@import "/__global/global.scss";
html,body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body{
  background: $main;
  font-family: $sec_font;
  //padding: 40px;
}
h1{
  font-family: $main_font;
  font-size: 25px;
  color:$main;
  text-align: center;
  margin-top: 30px;
}


.content{
  margin: 0 auto;
  //margin-top: 60px;
  position: relative;
  width: 100vw;
  height: 100vh;
  padding: 60px 10px;
  //margin: 50px;
  background-image: url("./assets/scroll.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

